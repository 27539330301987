import React from 'react';

import { CoacheeExperience, CoacheeFeedback } from './components';
import LookerDashboardLayout from '../components/LookerDashboardLayout';

export function LookerSatisfactionDashboard() {
  return (
    <LookerDashboardLayout>
      <CoacheeExperience />
      <CoacheeFeedback />
    </LookerDashboardLayout>
  );
}
