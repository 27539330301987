import React, { useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { LinearProgress, makeStyles } from '@coachhubio/ui-components';
import { GET_ORGANIZATION_QUERY } from 'apollo/organization-api/organization';
import { GetOrganization } from 'apollo/organization-api/types/GetOrganization';
import { useFlags } from 'hooks/useFlags';
import { getI18n, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fetchApi } from 'utils/fetchApi';
import { currentTenant } from 'utils/multitenant';

import { LookerDashboardError } from './components/LookerDashboardError';
import { Iframe } from './Iframe';
import { useUser } from '../../../src/context/AuthContext';
import { Title } from '../../components/Title';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: '100vh',
    backgroundColor: theme.brandPalette.white,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    flexGrow: 1,
    padding: 20,
  },
  wrapperScaled: {
    width: '100%',
    maxWidth: 1332,
    margin: '0 auto',
  },
  iframePadding: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    [`@media screen and (min-width: 1060px)`]: {
      paddingLeft: '1.25rem',
      paddingRight: '1.25rem',
    },
    [`@media screen and (min-width: 1490px)`]: {
      paddingLeft: '3.75rem',
      paddingRight: '3.75rem',
    },
  },
  container: {
    marginBottom: 0,
    height: '100%',
    width: '100%',
  },
  containerScaled: {
    marginBottom: 0,
    height: '100%',
  },
  logo: {
    height: '50px',
    padding: '10px',
  },
  title: {
    justifyContent: 'space-between',
    height: '65px',
  },
}));

const SIframe = styled(Iframe)`
  height: 100%;
  width: 100%;
`;

export function LookerDashboard({ dashId, iframeHeight }: { dashId: number; iframeHeight?: number }) {
  const { t } = useTranslation('Dashboard');
  const classes = useStyles({});
  const [lookerUrl, setLookerUrl] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [orgData, setOrganization] = useState<any>(undefined);
  const [error, setError] = useState<boolean>(false);
  const { showDashboardSections } = useFlags();
  const user = useUser();
  const language = getI18n().language;
  const apolloClient = useApolloClient();

  useEffect(() => {
    const fetchOrg = async () => {
      const { data: organizationData } = await apolloClient.query<GetOrganization>({
        query: GET_ORGANIZATION_QUERY,
        variables: {
          organizationId: user.organizationId,
        },
        context: { clientName: currentTenant(user.tenantId) },
      });

      setOrganization(organizationData.getOrganization);
    };

    fetchOrg();
  }, [user.organizationId, user.tenantId, apolloClient]);

  useEffect(() => {
    const method = showDashboardSections ? 'lookerDashboardUrlById' : 'lookerDashboardUrl';

    // Make sure to only perform state updates on a mounted component, see: https://www.debuggr.io/react-update-unmounted-component/
    let mounted = true;
    (async () => {
      let data: { url: string };

      if (mounted) setLoading(true);

      try {
        const result = await fetchApi(`/api/${method}?lang=${language}${showDashboardSections && `&id=${dashId}`}`, {
          method: 'GET',
        });

        data = await result.json();

        if (mounted) setLookerUrl(data.url);
      } catch (err) {
        console.error('Could not get looker url', err);
        if (mounted) setError(true);
      }

      if (mounted) setLoading(false);
    })();
    return () => {
      mounted = false;
    };
  }, [user.organizationId, dashId, language, showDashboardSections]);

  return (
    <div className={`${classes.wrapper} ${showDashboardSections ? '' : classes.wrapperScaled}`}>
      <Title data-testid="head-section" className={`${classes.iframePadding} ${classes.title}`}>
        {!showDashboardSections && t('title')}
        {showDashboardSections && orgData && <span data-hj-suppress>{`${orgData.name}`}</span>}
        {showDashboardSections && orgData && orgData.logo && (
          <img src={orgData.logo} alt={`${orgData.name} logo`} className={classes.logo} data-hj-suppress />
        )}
      </Title>
      <div className={`${showDashboardSections ? classes.containerScaled : classes.container}`}>
        {(loading || !orgData) && (
          <div className={classes.iframePadding}>
            <LinearProgress />
          </div>
        )}
        {!loading && error && <LookerDashboardError></LookerDashboardError>}
        {!loading && !error && lookerUrl && <SIframe height={`${iframeHeight}`} url={lookerUrl}></SIframe>}
      </div>
    </div>
  );
}
