import React from 'react';

import { marginBottom, paddingTop } from '@coachhubio/nova-spaces';
import useGetLookerData from 'hooks/useGetLookerData';
import { useTranslation } from 'react-i18next';
import { Column, SubTitle } from 'screens/Dashboard/components/Common';
import { DownloadDropdownIconButton } from 'screens/Dashboard/components/DownloadDropdownIconButton';
import styled from 'styled-components';

import AssignmentCard from './AssignmentCard';
import { LookerFieldsTag } from '../../utils/lookerFields';

const fallbackList = [...Array(5)];

export const AssignmentTitle = styled(SubTitle)`
  ${marginBottom('none')}
`;

const AssignmentsList = styled(Column)`
  ${paddingTop('l')}
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export type LearningAcademyTopAssignment = {
  'nuggets_assigned.title': string;
  row: number;
  new_calculation: string;
  coachees_assigned_to: number;
  percent_of_employees_nuggets_assigned_to: number;
  employees_assigned: string;
  total_assignment: number;
};

enum TableColumns {
  EmployeesAssigned = 'coachees_assigned_to',
  NuggetsAssigned = 'nuggets_assigned.title',
}

const TopAssignmentsList = () => {
  const { t } = useTranslation('Dashboard');

  const { loading, data = [] } = useGetLookerData<LearningAcademyTopAssignment[]>(LookerFieldsTag.TopAssignments);

  const topAssignments =
    data.length > 0 ? data.slice(0, 5) : (fallbackList as unknown as LearningAcademyTopAssignment[]);

  return (
    <Column>
      <TitleSection>
        <AssignmentTitle>{t('engagement.academy.top.title')}</AssignmentTitle>
        <DownloadDropdownIconButton
          lookName={LookerFieldsTag.TopAssignments}
          columns={{
            [TableColumns.EmployeesAssigned]: t('engagement.assignments.value.download'),
            [TableColumns.NuggetsAssigned]: t('engagement.assignments.title.download'),
          }}
          option="csv"
        />
      </TitleSection>
      <AssignmentsList>
        {topAssignments.map((assignment, index) => {
          return <AssignmentCard key={`assignment-${index}`} isLoading={loading} assignment={assignment} />;
        })}
      </AssignmentsList>
    </Column>
  );
};

export default TopAssignmentsList;
