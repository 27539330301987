import React from 'react';
import type { ReactElement } from 'react';

import { Skeleton } from '@coachhubio/nova-skeleton';
import { gap, paddingBottom } from '@coachhubio/nova-spaces';
import { Tag } from '@coachhubio/nova-tag';
import useGetLookerData from 'hooks/useGetLookerData';
import { useTranslation } from 'react-i18next';
import { LookerFieldsTag } from 'screens/Dashboard/utils/lookerFields';
import styled from 'styled-components';

import AreasForImprovementCard from './AreasForImprovementCard';
import TopStrengthChartCard from './TopStrengthChartCard';
import { Column, Placeholder, CenteredRow } from '../../components/Common';
import { SectionHeader } from '../../components/SectionHeader';

const SectionColumn = styled(Column)`
  flex-grow: 0;
`;

const TagSection = styled(CenteredRow)`
  ${paddingBottom('l')}
`;

const GraphRow = styled.div`
  display: flex;
  flex-direction: row;
  ${gap('l')}
`;

type CoacheesPerAssessment = {
  'assessments.coachees_strength_assessment': number;
  focus_area_rated: string;
};
const StrengthAssessmentSection = (): ReactElement => {
  const { loading, data = [] } = useGetLookerData<CoacheesPerAssessment[]>(LookerFieldsTag.StrengthAssessmentCoachees);
  const { t } = useTranslation('Dashboard');
  const coacheesPerAssessment = data[0];

  return (
    <SectionColumn>
      <SectionHeader
        i18title="impact.assessments.strengths.title"
        i18description="impact.assessments.strengths.description"
      />
      <TagSection>
        {!loading ? (
          <>
            {coacheesPerAssessment?.['assessments.coachees_strength_assessment'] ? (
              <Tag variant="hint">
                {t('impact.assessments.tag', {
                  count: coacheesPerAssessment?.['assessments.coachees_strength_assessment'],
                })}
              </Tag>
            ) : (
              <Tag variant="hint">{t('common.no_data')}</Tag>
            )}
          </>
        ) : (
          <Placeholder $height="16px" $width="350px">
            <Skeleton height="16px" width="350px" />
          </Placeholder>
        )}
      </TagSection>
      <GraphRow>
        <TopStrengthChartCard />
        <AreasForImprovementCard />
      </GraphRow>
    </SectionColumn>
  );
};

export default StrengthAssessmentSection;
