import React from 'react';

import { getBorder } from '@coachhubio/nova-borders';
import { Button } from '@coachhubio/nova-button';
import { InfoIcon, FaceHappyIcon, FaceSadIcon } from '@coachhubio/nova-icon';
import { Skeleton } from '@coachhubio/nova-skeleton';
import { marginStart, marginTop, padding } from '@coachhubio/nova-spaces';
import { Tag } from '@coachhubio/nova-tag';
import { Heading, Text } from '@coachhubio/nova-text';
import DOMPurify from 'dompurify';
import useGetLookerData from 'hooks/useGetLookerData';
import { useLocale } from 'hooks/useLocale';
import ReactHtmlParser from 'html-react-parser';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { LookerFieldsTag } from 'screens/Dashboard/utils/lookerFields';
import styled from 'styled-components';

import { Row, Column, Placeholder, SubTitle, Description, CenteredRow, BrandTag } from '../../components/Common';

const GraphSatis = styled.div`
  display: flex;
  width: 100%;

  ${padding('l')}
  ${getBorder({ color: 'neutral80', width: 's', radius: 'm' })}

  @media (min-width: 1390px) {
    width: 50%;
  }
`;

const SeeMoreButton = styled(Button)`
  align-self: center;

  ${marginTop('l')}
`;

const SatisfactionContainer = styled(Column)`
  ${marginTop('xl')}
`;

const SatifactionInfo = styled(Column)`
  & > * {
    ${marginTop('xs')}
  }

  & > *:last-child {
    ${marginTop('m')}
  }
`;

const HappyIcon = styled(FaceHappyIcon)`
  height: 44px;
  width: 44px;

  ${marginStart('s')}
`;

const SadIcon = styled(FaceSadIcon)`
  height: 44px;
  width: 44px;

  ${marginStart('s')}
`;

type SessionRating = {
  'coachee_sessions.m_avg_session_ratings': number;
  'benchmark_fixed_period.benchmark_value': number;
};

type SessionRated = {
  'coachee_sessions.m_sessions_with_rating': string;
};

export function GraphSatisfaction() {
  const { t } = useTranslation('Dashboard');
  const locale = useLocale();
  const router = useRouter();

  const { loading, data: sessionRating } = useGetLookerData<SessionRating[]>(LookerFieldsTag.SatisfactionSessionRating);
  const { loading: loadingRated, data: sessionRated } = useGetLookerData<SessionRated[]>(
    LookerFieldsTag.SatisfactionSessionRated
  );

  const satisRating =
    sessionRating && sessionRating.length && sessionRating[0]['coachee_sessions.m_avg_session_ratings']
      ? new Intl.NumberFormat(locale).format(
          Number(sessionRating[0]['coachee_sessions.m_avg_session_ratings'].toFixed(1))
        )
      : undefined;

  const satisBenchmark =
    sessionRating && sessionRating.length && sessionRating[0]['benchmark_fixed_period.benchmark_value']
      ? new Intl.NumberFormat(locale).format(
          Number(sessionRating[0]['benchmark_fixed_period.benchmark_value'].toFixed(1))
        )
      : undefined;

  const satisRatedCount =
    sessionRated && sessionRated.length && sessionRated[0]['coachee_sessions.m_sessions_with_rating']
      ? parseInt(sessionRated[0]['coachee_sessions.m_sessions_with_rating'])
      : undefined;

  return (
    <GraphSatis>
      <Column>
        <SubTitle>{t('overview.satisfaction.title')}</SubTitle>
        <Description color="primaryDark25" size="s">
          {t('overview.satisfaction.description')}
        </Description>
        {!loadingRated ? (
          <>
            {satisRatedCount !== undefined ? (
              <Tag variant="hint">
                {t('overview.satisfaction.sessions_rated', {
                  count: satisRatedCount,
                })}
              </Tag>
            ) : (
              <Tag variant="hint">{t(`common.no_data`)}</Tag>
            )}
          </>
        ) : (
          <Placeholder $height="16px" $width="200px">
            <Skeleton height="16px" width="200px" />
          </Placeholder>
        )}
        <SatisfactionContainer>
          <Heading color="primaryDark35" size="xl">
            <Row>
              {!loading ? (
                <>
                  {satisRating !== undefined ? (
                    <>
                      {`${satisRating}/5`}
                      {parseFloat(satisRating) >= 4 ? <HappyIcon /> : <SadIcon />}
                    </>
                  ) : (
                    <Heading color="primaryDark35" size="xl">
                      {t(`common.no_data`)}
                    </Heading>
                  )}
                </>
              ) : (
                <Placeholder $height="42px" $width="120px">
                  <Skeleton height="42px" width="120px" />
                </Placeholder>
              )}
            </Row>
          </Heading>
        </SatisfactionContainer>
        <SatifactionInfo>
          <Text size="s" modifier="bold" color="primaryDark25">
            {t('overview.satisfaction.avg_session.description')}
          </Text>
          {sessionRating || loading ? (
            <>
              {!loading ? (
                satisBenchmark !== undefined ? (
                  <BrandTag>{t('overview.satisfaction.avg_session.benchmark', { number: satisBenchmark })}</BrandTag>
                ) : (
                  <BrandTag> {t(`common.no_data`)} </BrandTag>
                )
              ) : (
                <Placeholder $height="16px" $width="70px">
                  <Skeleton height="16px" width="70px" />
                </Placeholder>
              )}
            </>
          ) : (
            <Tag variant="hint">{t(`common.no_data`)}</Tag>
          )}

          <Tag iconPosition="start" variant="hint" Icon={InfoIcon}>
            {t('overview.satisfaction.avg_session.benchmark_info')}
          </Tag>
          <Text color="primaryDark25" size="s">
            {!loading ? (
              <>
                {satisRating ? (
                  <>
                    {ReactHtmlParser(
                      DOMPurify.sanitize(
                        t(
                          `overview.satisfaction.avg_session.insight.${
                            parseFloat(satisRating) >= 4 ? 'positive' : 'negative'
                          }`
                        )
                      )
                    )}
                  </>
                ) : (
                  <Tag variant="hint">{t(`common.no_data`)}</Tag>
                )}
              </>
            ) : (
              <Placeholder $height="60px" $width="100%">
                <Skeleton height="60px" width="100%" />
              </Placeholder>
            )}
          </Text>
        </SatifactionInfo>
        <CenteredRow>
          <SeeMoreButton onClick={() => router.push('/satisfaction')} variant="secondary">
            {t('overview.satisfaction.seemore')}
          </SeeMoreButton>
        </CenteredRow>
      </Column>
    </GraphSatis>
  );
}
