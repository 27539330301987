import React, { useRef } from 'react';

import { paddingTop } from '@coachhubio/nova-spaces';
import useGetLookerData from 'hooks/useGetLookerData';
import { useTranslation } from 'react-i18next';
import { Column, SubTitle, CardHeader } from 'screens/Dashboard/components/Common';
import { LookerFieldsTag } from 'screens/Dashboard/utils/lookerFields';
import styled from 'styled-components';

import CategoryRow from './CategoryRow';
import { DownloadDropdownIconButton } from '../../components/DownloadDropdownIconButton';
import HeaderList from '../../components/HeaderList';

const fallbackList = [...Array(5)];

const CategoriesList = styled(Column)`
  ${paddingTop('m')}
`;

export interface ImpactFocusAreaProgressByCategory {
  'focus_areas.categorytext': string;
  row: number;
  average_of_last_rating: number;
  average_of_goal: number;
  goal_attainment: number;
  goal_attainment_text: string;
}

const csvColumns = {
  categories: {
    lookerKey: 'focus_areas.categorytext',
    i18titleKey: 'impact.header.focus.areas.title',
  },
  goalAttainment: {
    lookerKey: 'goal_attainment_text',
    i18titleKey: 'impact.header.goal.reached',
  },
};

const TopCategories = () => {
  const { t } = useTranslation('Dashboard');
  const ref = useRef<HTMLDivElement>(null);
  const { loading, data = [] } = useGetLookerData<ImpactFocusAreaProgressByCategory[]>(LookerFieldsTag.TopCategories);

  const topCategories =
    data.length > 0 ? data.slice(0, 5) : (fallbackList as unknown as ImpactFocusAreaProgressByCategory[]);

  return (
    <Column>
      <CardHeader ref={ref}>
        <SubTitle>{t('impact.top.categories.title')}</SubTitle>
        <DownloadDropdownIconButton
          element={ref}
          lookName={LookerFieldsTag.TopCategories}
          option="csv"
          columns={{
            [csvColumns.categories.lookerKey]: t(csvColumns.categories.i18titleKey),
            [csvColumns.goalAttainment.lookerKey]: t(csvColumns.goalAttainment.i18titleKey),
          }}
        />
      </CardHeader>
      <HeaderList />
      <CategoriesList>
        {topCategories.map((category, index) => (
          <CategoryRow isLoading={loading} category={category} key={`category-row-${index}`} row={index + 1} />
        ))}
      </CategoriesList>
    </Column>
  );
};

export default TopCategories;
