import React from 'react';

import { Button } from '@coachhubio/nova-button';
import { marginTop, marginY, gap } from '@coachhubio/nova-spaces';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import EngagementBanner from './EngagementBanner';
import { GraphActivationJourney } from './GraphActivationJourney';
import { GraphAverageSession } from './GraphAverageSession';
import { Row, Column, CenteredRow } from '../../components/Common';

export const GraphRow = styled(Row)`
  flex-wrap: wrap;

  ${gap('l')}

  @media (min-width: 1390px) {
    flex-wrap: nowrap;
  }
`;

const SeeMoreEngagement = styled(Button)`
  align-self: center;

  ${marginTop('l')}
  ${marginY('2xl')}
`;

export function EngagementSection() {
  const { t } = useTranslation('Dashboard');
  const router = useRouter();

  return (
    <Column>
      <EngagementBanner />
      <GraphRow>
        <GraphAverageSession />
        <GraphActivationJourney />
      </GraphRow>
      <CenteredRow>
        <SeeMoreEngagement onClick={() => router.push('/engagement')} variant="secondary">
          {t('overview.engagement.seemore')}
        </SeeMoreEngagement>
      </CenteredRow>
    </Column>
  );
}
