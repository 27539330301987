import React, { useRef } from 'react';

import { paddingTop } from '@coachhubio/nova-spaces';
import useGetLookerData from 'hooks/useGetLookerData';
import { useTranslation } from 'react-i18next';
import { Column, CardHeader, SubTitle } from 'screens/Dashboard/components/Common';
import { LookerFieldsTag } from 'screens/Dashboard/utils/lookerFields';
import styled from 'styled-components';

import FocusAreaRow from './FocusAreaRow';
import { DownloadDropdownIconButton } from '../../components/DownloadDropdownIconButton';
import HeaderList from '../../components/HeaderList';

const fallbackList = [...Array(5)];

const List = styled(Column)`
  ${paddingTop('m')}
`;

export interface ImpactFocusAreaProgressGoalAchievement {
  'focus_areas.icon': string;
  'focus_areas.title': string;
  'focus_areas.icon_focusareas_by_coachee': string;
  row: number;
  average_of_last_rating: number;
  average_of_goal: number;
  goal_attainment: number;
  goal_attainment_text: string;
}

const csvColumns = {
  focusArea: {
    lookerKey: 'focus_areas.title',
    i18titleKey: 'impact.header.focus.areas.title',
  },
  goalAttainment: {
    lookerKey: 'goal_attainment_text',
    i18titleKey: 'impact.header.goal.reached',
  },
};

const TopFocusAreas = () => {
  const { t } = useTranslation('Dashboard');
  const ref = useRef<HTMLDivElement>(null);
  const { loading, data = [] } = useGetLookerData<ImpactFocusAreaProgressGoalAchievement[]>(
    LookerFieldsTag.TopFocusAreas
  );

  const focusAreas =
    data.length > 0 ? data.slice(0, 5) : (fallbackList as unknown as ImpactFocusAreaProgressGoalAchievement[]);

  return (
    <Column ref={ref}>
      <CardHeader>
        <SubTitle>{t('impact.top.focus.areas.title')}</SubTitle>
        <DownloadDropdownIconButton
          element={ref}
          lookName={LookerFieldsTag.TopFocusAreas}
          option="csv"
          columns={{
            [csvColumns.focusArea.lookerKey]: t(csvColumns.focusArea.i18titleKey),
            [csvColumns.goalAttainment.lookerKey]: t(csvColumns.goalAttainment.i18titleKey),
          }}
        />
      </CardHeader>
      <HeaderList />
      <List>
        {focusAreas.map((area, index) => (
          <FocusAreaRow isLoading={loading} area={area} key={`focus-row-${index}`} />
        ))}
      </List>
    </Column>
  );
};

export default TopFocusAreas;
