import React, { useState } from 'react';
import type { ReactElement, RefObject } from 'react';

import { Banner } from '@coachhubio/nova-banner';
import { getBorder } from '@coachhubio/nova-borders';
import { Button } from '@coachhubio/nova-button';
import { getBackgroundColor } from '@coachhubio/nova-colors';
import { gap, marginBottom, marginTop, padding } from '@coachhubio/nova-spaces';
import { Heading, Text } from '@coachhubio/nova-text';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DownloadDropdownButton } from './DownloadDropdownButton';
import { SectionHeader } from './SectionHeader';
import GoalSettingLight from '../../../assets/goal-setting-light.svg';
import GrowthLight from '../../../assets/icon-growth-light.svg';
import SatisfationBannerIcon from '../../../assets/satisfaction-banner-icon.svg';
import StarLight from '../../../assets/star1light.svg';
import { Row } from '../components/Common';

type SectionBannerProps = {
  page: string;
  pdfDonwloadRef?: RefObject<HTMLDivElement>;
};

const BannerContainer = styled.div``;

const TitleRow = styled(Row)`
  justify-content: space-between;
`;

const CustomBanner = styled(Banner)<{ $page: string }>`
  display: flex;
  width: 90%;

  ${marginBottom('m')}
  ${gap('base')}
  ${getBorder({
    color: 'neutral80',
    width: 's',
    radius: 'm',
  })}

  ${({ $page }) => $page !== 'overview' && marginBottom('2xl')}
`;

const IconContainer = styled.div`
  display: flex;
  width: 64px;
  height: 64px;

  ${padding('2xs')}
`;

const DownloadButton = styled(DownloadDropdownButton)`
  align-self: center;
`;

const BannerContent = styled.div`
  margin-inline-start: 16px;
`;

const BannerTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BannerButton = styled(Button)`
  ${marginTop('base')}
  ${marginBottom('xs')}
  ${getBackgroundColor('transparent')}
`;

export function SectionBanner({ page, pdfDonwloadRef }: SectionBannerProps): ReactElement {
  const { t } = useTranslation('Dashboard');

  const [showBanner, setShowBanner] = useState<boolean>(false);

  let i18title, title, description, Icon;

  switch (page) {
    case 'overview':
      i18title = 'common.pagemenu.overview';
      title = t('common.pagemenu.overview');
      description = t('overview.banner.description');
      Icon = GrowthLight;
      break;
    case 'engagement':
      i18title = 'overview.engagement.title';
      title = t('overview.engagement.title');
      description = t('overview.engagement.description');
      Icon = GoalSettingLight;
      break;
    case 'impact':
      i18title = 'overview.impact.title';
      title = t('overview.impact.title');
      description = t('impact.banner.description');
      Icon = StarLight;
      break;
    case 'satisfaction':
      i18title = 'overview.satisfaction.title';
      title = t('overview.satisfaction.title');
      description = t('overview.satisfaction.description');
      Icon = SatisfationBannerIcon;
      break;
    default:
      return <></>;
  }

  const closeBanner = () => {
    setShowBanner(false);
  };

  return (
    <BannerContainer>
      <TitleRow>
        <SectionHeader
          i18title={i18title}
          position="start"
          withIcon
          showBanner={showBanner}
          setShowBanner={setShowBanner}
        />
        <DownloadButton option="pdf" element={pdfDonwloadRef}>
          {t('overview.download')}
        </DownloadButton>
      </TitleRow>
      {showBanner ? (
        <CustomBanner
          $page={page}
          type="hint"
          withIcon={false}
          withIconAlign={false}
          direction="column"
          onClose={closeBanner}
        >
          <IconContainer>
            <img src={Icon} alt="banner icon" />
          </IconContainer>
          <BannerContent>
            <BannerTextContainer>
              <Heading color="primaryDark35">{title}</Heading>
              <Text color="primaryDark35" size="s">
                {description}
              </Text>
            </BannerTextContainer>
            <BannerButton variant="secondary" onClick={closeBanner}>
              {t('overview.engagement.banner_button_label')}
            </BannerButton>
          </BannerContent>
        </CustomBanner>
      ) : null}
    </BannerContainer>
  );
}
