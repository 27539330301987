import React, { useState, useMemo } from 'react';
import type { ReactElement } from 'react';

import { getBorder, borderRadius } from '@coachhubio/nova-borders';
import { Button, ButtonTextElement } from '@coachhubio/nova-button';
import { getBackgroundColor } from '@coachhubio/nova-colors';
import { DropdownButton } from '@coachhubio/nova-dropdown-button';
import { DownloadIcon } from '@coachhubio/nova-icon';
import { Skeleton } from '@coachhubio/nova-skeleton';
import { gap, marginTop } from '@coachhubio/nova-spaces';
import { Heading, Text } from '@coachhubio/nova-text';
import { defaultFilterValues } from 'hooks/useLookerFilters';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { downloadtoCSVDistributionOfAssignments } from 'utils/downloadNestedDataCSV';
import { FiltersData } from 'utils/fetchDashboard';

import { FocusAreaData } from './EngagementLearningAcademyAssignmentsByFocusAreaGraph';
import useGetLookerData from '../../../../hooks/useGetLookerData';
import { InlinePlaceholder } from '../../components/Common';
import { LookerFieldsTag } from '../../utils/lookerFields';

import { EngagementLearningAcademyAssignmentsByFocusAreaGraph } from './';

export type FocusAreaCategoryType = Partial<Record<Category, number | null>>;

export type NuggetsAssignedType = {
  ['nuggets_assigned.focus_area_category']: FocusAreaCategoryType;
};
export interface ILearningAcademyData {
  ['nuggets_assigned.created_at_month']: string;
  ['nuggets_assigned.nuggets_assigned']?: NuggetsAssignedType;
  ['percent_of_nuggets_assigned_nuggets_assigned']: NuggetsAssignedType;
}

const ChartContainer = styled.div`
  width: 100%;
  padding: 36px 33px;

  ${getBorder({ color: 'neutral80', width: 's', radius: 'l' })}
`;

const HeadingContainer = styled.div`
  display: flex;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
`;

const Subtext = styled(Text)`
  color: #51537b;
  margin-top: 11px;
`;

const DownloadButtonContainer = styled.div`
  display: flex;
  align-self: center;
`;

const ControlButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  ${gap('s')}
  ${marginTop('2xl')}
`;

const FilterButton = styled(Button)<{ $buttonColor: string }>`
  border: 1px solid #7b61ff;
  position: relative;

  ${ButtonTextElement} {
    font-weight: 400;
  }

  &:hover {
    background-color: #f1f1f4 !important;
  }

  &:disabled {
    background-color: #dddee9 !important;
  }

  ${getBackgroundColor('transparent')}
  ${borderRadius('l')}

  ${({ $buttonColor }) =>
    $buttonColor !== '#B5B6C9'
      ? css`
          border: 1px solid ${$buttonColor};

          ${ButtonTextElement} {
            color: ${$buttonColor};
          }
        `
      : css`
          border: 1px solid #b5b6c9;

          ${ButtonTextElement} {
            color: #b5b6c9;
          }
        `}
`;

const GraphContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const CATEGORIES = [
  'common::looker::localization::balance',
  'common::looker::localization::communication',
  'common::looker::localization::empowerment',
  'common::looker::localization::performance',
  'common::looker::localization::presence',
  'common::looker::localization::purpose',
] as const;
export type Category = (typeof CATEGORIES)[number];
export type ExtendedCategory = Category | 'common.fa.all';

export type ASSIGNMENTS_BY_FOCUS_AREA_PARAM = {
  color: string;
  i18key: ExtendedCategory;
  formattedKey: string;
};
export const ASSIGNMENTS_BY_FOCUS_AREA_PARAMS: ASSIGNMENTS_BY_FOCUS_AREA_PARAM[] = [
  {
    color: '#B5B6C9',
    i18key: 'common.fa.all',
    formattedKey: 'common.fa.all',
  },
  {
    color: '#313391',
    i18key: 'common::looker::localization::balance',
    formattedKey: 'common.looker.localization.balance',
  },
  {
    color: '#7b61ff',
    i18key: 'common::looker::localization::communication',
    formattedKey: 'common.looker.localization.communication',
  },
  {
    color: '#97CFFF',
    i18key: 'common::looker::localization::empowerment',
    formattedKey: 'common.looker.localization.empowerment',
  },
  {
    color: '#B88700',
    i18key: 'common::looker::localization::performance',
    formattedKey: 'common.looker.localization.performance',
  },
  {
    color: '#FF8155',
    i18key: 'common::looker::localization::presence',
    formattedKey: 'common.looker.localization.presence',
  },
  {
    color: '#0ca789',
    i18key: 'common::looker::localization::purpose',
    formattedKey: 'common.looker.localization.purpose',
  },
];

const DEFAULT_FILTERED_MONTHS = 6;

const DistributionOfAssignmentsChartCard = ({ filters }: { filters: FiltersData }): ReactElement => {
  const { t } = useTranslation('Dashboard');

  // the colorKey is used to keep reference of the selected filter
  const [colorKey, setColorKey] = useState<string>('common.fa.all');

  const customFilters = useMemo(
    () => ({
      ...filters,
      data_range: defaultFilterValues.data_range,
    }),
    [filters]
  );

  const { data = [], loading } = useGetLookerData<ILearningAcademyData[]>(
    LookerFieldsTag.AssignmentsByFocusArea,
    customFilters
  );

  // We keep only the data we need to display
  const filteredData = useMemo(() => {
    return data.map((el) => {
      const values: FocusAreaData['values'] = CATEGORIES.filter(
        (c) => colorKey === 'common.fa.all' || c === colorKey
      ).reduce(
        (acc, c) => ({
          ...acc,
          [c]: el['percent_of_nuggets_assigned_nuggets_assigned']?.['nuggets_assigned.focus_area_category']?.[c],
        }),
        {}
      );
      const entry: FocusAreaData = {
        date: el['nuggets_assigned.created_at_month'],
        values,
      };
      return entry;
    });
  }, [data, colorKey]);

  return (
    <ChartContainer>
      <HeadingContainer>
        <TextContainer>
          <Heading color="brandDark" size="m">
            {t('engagement.academy.distribution.title')}
          </Heading>
          <Subtext size="xs" type="text">
            {t('engagement.academy.distribution.subtitle', { count: DEFAULT_FILTERED_MONTHS })}
          </Subtext>
        </TextContainer>
        <DownloadButtonContainer>
          <DropdownButton
            withChevron={false}
            menuItems={[
              {
                items: [
                  {
                    label: t('overview.licenses.download.csv'),
                    onClick: () =>
                      downloadtoCSVDistributionOfAssignments(LookerFieldsTag.AssignmentsByFocusArea, data, t),
                  },
                ],
              },
            ]}
            buttonVariant="icon"
            buttonSize="s"
            autoFlip
          >
            <DownloadIcon />
          </DropdownButton>
        </DownloadButtonContainer>
      </HeadingContainer>
      <ControlButtonContainer>
        {ASSIGNMENTS_BY_FOCUS_AREA_PARAMS.map((focusArea, i) => {
          return (
            <FilterButton
              squared
              key={i}
              $buttonColor={focusArea.color as string}
              onClick={() => setColorKey(focusArea.i18key)}
              disabled={colorKey === focusArea.i18key}
            >
              {t(focusArea.formattedKey)}
            </FilterButton>
          );
        })}
      </ControlButtonContainer>
      <GraphContainer>
        {data.length === 0 && !loading ? (
          <Heading color="brandDark" size="l">
            {t(`common.no_data`)}
          </Heading>
        ) : (
          <>
            {loading ? (
              <InlinePlaceholder $height="440px" $width="1020px">
                <Skeleton height="440px" width="1020px" />
              </InlinePlaceholder>
            ) : (
              <EngagementLearningAcademyAssignmentsByFocusAreaGraph data={filteredData} />
            )}
          </>
        )}
      </GraphContainer>
    </ChartContainer>
  );
};

export default DistributionOfAssignmentsChartCard;
