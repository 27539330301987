import React, { Fragment, useCallback, useMemo, useState } from 'react';

import { getBorder } from '@coachhubio/nova-borders';
import { Button } from '@coachhubio/nova-button';
import { ButtonIcon } from '@coachhubio/nova-button';
import { getBackgroundColor } from '@coachhubio/nova-colors';
import { GridTable, GridTableCell, GridTableHeaderCell } from '@coachhubio/nova-grid-table';
import { ArrowUpIcon, ChevronRightIcon, ChevronDownIcon, DownloadIcon } from '@coachhubio/nova-icon';
import { Skeleton } from '@coachhubio/nova-skeleton';
import { gap, marginBottom, marginEnd, marginY, padding, paddingY } from '@coachhubio/nova-spaces';
import { Tag } from '@coachhubio/nova-tag';
import { Text } from '@coachhubio/nova-text';
import { useLooker } from 'context/LookerContext';
import useGetLookerData from 'hooks/useGetLookerData';
import { useTableSortingIcon, SortDirections } from 'hooks/useTableSortingIcon';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { LookerFieldsTag } from 'screens/Dashboard/utils/lookerFields';
import styled from 'styled-components';
import { getReport } from 'utils/fetchDashboard';

import ProgressGoalsBar from './ProgressGoalsBar';
import ProgressGoalsLegend from './ProgressGoalsLegend';
import { useLocale } from '../../../../hooks/useLocale';
import { localizedNumber } from '../../../../utils/i18n';
import { Row, Column, Placeholder, Title } from '../../components/Common';

const BASE_KEY = 'impact.progress.focusarea';

const ProgressGoalsWrapper = styled(Column)`
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 0;

  ${padding('xl')}
  ${marginY('5xl')}
  ${getBorder({ color: 'neutral85', width: 's', radius: 'l' })}
`;

const TitleRow = styled(Row)`
  width: 100%;
  flex-grow: 0;

  ${marginBottom('3xl')}
`;

const TitleCol = styled(Column)`
  align-items: center;

  ${gap('s')}
`;

const DownloadButtonContainer = styled.div`
  display: flex;
  align-self: flex-start;
`;

const ExpandButton = styled(ButtonIcon)`
  ${marginEnd('s')}
`;
const ProgressText = styled(Text)`
  display: flex;
  align-items: center;
`;

const SubAreaCell = styled(GridTableCell)``;

const ProgressAndGoalsTable = styled(GridTable)`
  ${SubAreaCell} {
    ${getBorder({ width: 'none', positionRelative: false })}
    ${paddingY('s')}
  }
`;

const SubAreaTitle = styled(Text)`
  margin-inline-start: 52px;
`;

const ScaleRow = styled(Row)`
  justify-content: space-between;
`;

const CellPlaceholder = styled(Placeholder)`
  ${marginY('m')}
`;

const BlankPlaceholder = styled(Placeholder)`
  ${getBackgroundColor('neutral')}
`;

const enum TableKeys {
  TITLE = 'focus_areas.title',
  CATEGORY = 'focus_areas.category_cleanup',
  RATING = 'average_of_last_rating',
  PROGRESS = 'progress_made_1',
  COACHEE = 'count_of_coacheeid',
  FIRST_RATING = 'average_of_first_rating',
  LAST_RATING = 'average_of_last_rating',
  GOAL_RATING = 'average_of_goal',
  AREA = 'default_focus_area',
  UPDATE = 'count_of_coachee_with_multiple_ratings',
}
export type ProgressArea = { [key in TableKeys]: string | number };
export type ProgressAreas = ProgressArea[];
export type ProgressAreaEmbed = ProgressArea & { subAreas: ProgressAreas };

function countToSuffix(count: number) {
  switch (count) {
    case 0:
      return 'no_selected';
    case 1:
      return 'one';
    default:
      return 'other';
  }
}

function _renderCleanRatedCount(
  cleanRatedCount: number | undefined | null,
  cleanUpdateCount: number | undefined | null,
  t: TFunction
) {
  if (typeof cleanRatedCount === 'number' && typeof cleanUpdateCount === 'number') {
    if (cleanRatedCount === 0) {
      return <Tag variant="hint">{t(`${BASE_KEY}.rating.no_selected`)}</Tag>;
    }
    return (
      <Tag variant="hint">
        {t(`${BASE_KEY}.rating.${countToSuffix(cleanRatedCount)}`, {
          rated: cleanRatedCount,
          count: cleanUpdateCount,
        })}
      </Tag>
    );
  }

  return <Tag variant="hint">{t('common.no_data')}</Tag>;
}

function isEmpty(value: any): boolean {
  if (typeof value === 'undefined' || value === null) {
    return true;
  }
  if (typeof value === 'string') return Boolean(value.trim());

  if (typeof value === 'number') return false;
  return Boolean(value);
}

function ProgressGoalsTable() {
  const { t, i18n } = useTranslation('Dashboard');
  const { filters, looker } = useLooker();
  const locale = useLocale();

  const [orderBy, setOrder] = useState(TableKeys.RATING);
  const [orderDirection, setDirection] = useState<SortDirections>('none');
  const [rowExpandState, setExpandState] = useState<boolean[]>([]);

  const { getSortIcon, nextSortDirectionMap } = useTableSortingIcon(orderBy, orderDirection);

  const { data: focusAreaArray, loading } = useGetLookerData<ProgressAreas>(LookerFieldsTag.FocusAreaProgress);
  const { data: focusAreaCategory, loading: loadingCat } = useGetLookerData<ProgressAreas>(
    LookerFieldsTag.FocusAreaProgressCategories
  );

  const { data: updateCount, loading: loadingUpdate } = useGetLookerData<ProgressAreas>(
    LookerFieldsTag.CoacheesUpdateRatingCount
  );

  const [cleanRatedCount, cleanUpdateCount] = useMemo(() => {
    if (Array.isArray(updateCount) && updateCount.length > 0) {
      const { count_of_coacheeid: count, count_of_coachee_with_multiple_ratings: update } = updateCount[0];
      return [isEmpty(count) ? null : parseInt(count as string), isEmpty(update) ? null : parseInt(update as string)];
    }
    return [];
  }, [updateCount]);

  const orderedList: ProgressAreaEmbed[] = useMemo(() => {
    if (focusAreaArray && focusAreaCategory) {
      // Build Embed List from API response

      const embedList: ProgressAreaEmbed[] = focusAreaCategory.map((mainArea) => {
        const subAreas = focusAreaArray.filter((area) => area[TableKeys.CATEGORY] === mainArea[TableKeys.CATEGORY]);
        return { ...mainArea, subAreas };
      });

      let newOrderList = [...embedList];

      // Order Embed List
      if (orderDirection) {
        switch (orderBy) {
          case TableKeys.TITLE:
            newOrderList = newOrderList.sort((a, b) => (a[orderBy] as string).localeCompare(b[orderBy] as string));
            break;
          case TableKeys.RATING:
          case TableKeys.PROGRESS:
          case TableKeys.COACHEE:
            newOrderList = newOrderList.sort((a, b) => (a[orderBy] as number) - (b[orderBy] as number));
            break;
          default:
            break;
        }

        if (orderDirection === 'desc') {
          newOrderList.reverse();
        }
      }

      return newOrderList;
    }
    return [];
  }, [orderBy, focusAreaArray, focusAreaCategory, orderDirection]);

  const renderCleanRatedCount = useCallback(
    () => _renderCleanRatedCount(cleanRatedCount, cleanUpdateCount, t),
    [t, cleanRatedCount, cleanUpdateCount]
  );

  return (
    <ProgressGoalsWrapper>
      <TitleRow>
        <TitleCol>
          <Title id="progress-table">{t(`${BASE_KEY}.title`)}</Title>
          <Text>{t(`${BASE_KEY}.description`)}</Text>
          {!loadingUpdate ? (
            renderCleanRatedCount()
          ) : (
            <Placeholder $height="16px" $width="350px">
              <Skeleton height="16px" width="350px" />
            </Placeholder>
          )}
        </TitleCol>
        <DownloadButtonContainer>
          <Button
            variant="icon"
            squared
            onClick={() =>
              getReport(LookerFieldsTag.FocusAreaProgress, looker, filters, {
                [TableKeys.TITLE]: t(`${BASE_KEY}.header.focusarea`),
                [TableKeys.CATEGORY]: t(`${BASE_KEY}.header.category`),
                [TableKeys.RATING]: t(`${BASE_KEY}.header.rating`),
                [TableKeys.PROGRESS]: t(`${BASE_KEY}.header.progress`),
                [TableKeys.COACHEE]: t(`${BASE_KEY}.header.coachee`),
              })
            }
          >
            <DownloadIcon />
          </Button>
        </DownloadButtonContainer>
      </TitleRow>
      <ProgressAndGoalsTable columns={5}>
        {/* Table Header */}
        <GridTableHeaderCell>{t(`${BASE_KEY}.header.focusarea`)}</GridTableHeaderCell>
        <GridTableHeaderCell />
        <GridTableHeaderCell>
          {t(`${BASE_KEY}.header.rating`)}
          <ButtonIcon
            Icon={getSortIcon(TableKeys.RATING)}
            size="xs"
            marginStart="xs"
            onClick={() => {
              setDirection(orderBy === TableKeys.RATING ? nextSortDirectionMap[orderDirection] : 'asc');
              setOrder(TableKeys.RATING);
            }}
          />
        </GridTableHeaderCell>
        <GridTableHeaderCell>
          {t(`${BASE_KEY}.header.progress`)}
          <ButtonIcon
            Icon={getSortIcon(TableKeys.PROGRESS)}
            size="xs"
            marginStart="xs"
            onClick={() => {
              setDirection(orderBy === TableKeys.PROGRESS ? nextSortDirectionMap[orderDirection] : 'asc');
              setOrder(TableKeys.PROGRESS);
            }}
          />
        </GridTableHeaderCell>
        <GridTableHeaderCell>
          {t(`${BASE_KEY}.header.coachee`)}
          <ButtonIcon
            Icon={getSortIcon(TableKeys.COACHEE)}
            size="xs"
            marginStart="xs"
            onClick={() => {
              setDirection(orderBy === TableKeys.COACHEE ? nextSortDirectionMap[orderDirection] : 'asc');
              setOrder(TableKeys.COACHEE);
            }}
          />
        </GridTableHeaderCell>
        <>
          {/* Main Focus Areas */}
          {!loading && !loadingCat ? (
            orderedList.length ? (
              <>
                {orderedList.map((area: ProgressAreaEmbed, index: number) => {
                  const isRowExpanded = rowExpandState[index] || false;
                  const {
                    [TableKeys.CATEGORY]: category,
                    [TableKeys.RATING]: rating,
                    [TableKeys.PROGRESS]: progressStringOrNumber,
                    [TableKeys.COACHEE]: coachee,
                    [TableKeys.FIRST_RATING]: firstRating,
                    [TableKeys.LAST_RATING]: lastRating,
                    [TableKeys.GOAL_RATING]: goalRating,
                    subAreas,
                  } = area;

                  // using numberFormat to get comma separator translation
                  const cleanRating = new Intl.NumberFormat(i18n.language.replace('_', '-'), {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  }).format(rating as number);
                  const progress = Number(progressStringOrNumber);
                  const localizedProgress = localizedNumber({ locale, value: progress, options: { style: 'percent' } });

                  return (
                    <Fragment key={`${index}-row`}>
                      <GridTableCell>
                        {subAreas.length ? (
                          <>
                            <ExpandButton
                              Icon={isRowExpanded ? ChevronDownIcon : ChevronRightIcon}
                              onClick={() => {
                                let rows = [...rowExpandState];
                                rows[index] = !rowExpandState[index];
                                setExpandState(rows);
                              }}
                            />
                            <Text size="s" color="primaryDark25" modifier={isRowExpanded ? 'bold' : undefined}>
                              {t(category as string)}
                            </Text>
                          </>
                        ) : (
                          <SubAreaTitle size="s" color="primaryDark25">
                            {t(category as string)}
                          </SubAreaTitle>
                        )}
                      </GridTableCell>
                      <GridTableCell>
                        <ProgressGoalsBar
                          lastRating={Number(lastRating)}
                          firstRating={Number(firstRating)}
                          goalRating={Number(goalRating)}
                        />
                      </GridTableCell>
                      <GridTableCell>
                        <Text size="s" color="primaryDark25" modifier={isRowExpanded ? 'bold' : undefined}>
                          {`${cleanRating}/10`}
                        </Text>
                      </GridTableCell>
                      <GridTableCell>
                        <ProgressText color={progress > 0 ? 'success35' : 'neutral60'} size="s" modifier="bold">
                          {progress > 0 ? <ArrowUpIcon /> : <BlankPlaceholder $height="24px" $width="24px" />}
                          {localizedProgress}
                        </ProgressText>
                      </GridTableCell>
                      <GridTableCell>
                        <Text size="s" color="primaryDark25">
                          {coachee}
                        </Text>
                      </GridTableCell>
                      {/* Sub Focus Areas */}
                      {isRowExpanded ? (
                        <>
                          {subAreas.map((area) => {
                            const {
                              [TableKeys.TITLE]: title,
                              [TableKeys.RATING]: rating,
                              [TableKeys.PROGRESS]: progressStringOrNumber,
                              [TableKeys.COACHEE]: coachee,
                              [TableKeys.FIRST_RATING]: firstRating,
                              [TableKeys.LAST_RATING]: lastRating,
                              [TableKeys.GOAL_RATING]: goalRating,
                            } = area;

                            // using numberFormat to get comma separator translation
                            const cleanRating = new Intl.NumberFormat(i18n.language.replace('_', '-'), {
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 1,
                            }).format(rating as number);
                            const progress = Number(progressStringOrNumber);
                            const localizedProgress = localizedNumber({
                              locale,
                              value: progress,
                              options: { style: 'percent' },
                            });

                            return (
                              <>
                                <SubAreaCell>
                                  <SubAreaTitle size="s" color="primaryDark25">
                                    {t((title as string).replace(/::/g, '.'))}
                                  </SubAreaTitle>
                                </SubAreaCell>
                                <SubAreaCell>
                                  <ProgressGoalsBar
                                    lastRating={Number(lastRating)}
                                    firstRating={Number(firstRating)}
                                    goalRating={Number(goalRating)}
                                  />
                                </SubAreaCell>
                                <SubAreaCell>
                                  <Text size="s" color="primaryDark25">
                                    {`${cleanRating}/10`}
                                  </Text>
                                </SubAreaCell>
                                <SubAreaCell>
                                  <ProgressText
                                    color={progress > 0 ? 'success35' : 'neutral60'}
                                    size="s"
                                    modifier="bold"
                                  >
                                    {progress > 0 ? <ArrowUpIcon /> : <BlankPlaceholder $height="24px" $width="24px" />}
                                    {localizedProgress}
                                  </ProgressText>
                                </SubAreaCell>
                                <SubAreaCell>
                                  <Text size="s" color="primaryDark25">
                                    {coachee}
                                  </Text>
                                </SubAreaCell>
                              </>
                            );
                          })}
                        </>
                      ) : null}
                    </Fragment>
                  );
                })}
                {/* Last row for Scale */}
                <GridTableCell />
                <GridTableCell>
                  <ScaleRow>
                    {[...Array(10)].map((_, i) => (
                      <Text size="xs">{i + 1}</Text>
                    ))}
                  </ScaleRow>
                </GridTableCell>
                <GridTableCell />
                <GridTableCell />
                <GridTableCell />
              </>
            ) : null
          ) : (
            <>
              {Array.from({ length: 50 }).map((_, index) => (
                <GridTableCell key={`${index}-cell`}>
                  <CellPlaceholder $height="20px" $width={`${(index - 1) % 5 ? '1' : '4'}00px`}>
                    <Skeleton height="20px" width={`${(index - 1) % 5 ? '1' : '4'}00px`} />
                  </CellPlaceholder>
                </GridTableCell>
              ))}
            </>
          )}
        </>
      </ProgressAndGoalsTable>
      <ProgressGoalsLegend />
    </ProgressGoalsWrapper>
  );
}

export default ProgressGoalsTable;
